import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat, capitalizeFirstLetter } from "../../../components/Dashboard/General/Functions";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function FranchiseesDashboard() {


  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);
  const [Dashboard_data, setDashboard_data] = useState('');

  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  // pagination 
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Mag Holdings Admin";

    setIsUpdated(false);
    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: "Admin",
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Security/GetAllUsers',
      headers: {
        'Content-Type': 'application/json'
      },
      data: dashboardParms
    };


    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);

      })
      .catch(function (error) {
        // console.log(error);
      });

  }, [pageNo, pageSize, isUpdated]);

  const renderLedger = () => {

    if (listOfLedger.length > 0) {
      return (
        listOfLedger.map((item, index) => {
          return (
            <>
              <div className="col-md-3" key={index}>
                <Link to={`/admin/franchisees-dashboard/detail`}>
                  <div className="package-card">
                    <div className="content">
                      <div className={`ribbon ${`package_${index}`}`}>
                        <span className="ribbon-text" style={{ color: index === 0 ? '#fff' : '' }}>
                          {item.Full_Name}
                        </span>
                        <div className="ribbon-corner"></div>
                      </div>
                      <div className="package-header">
                        <h4>{capitalizeFirstLetter(item.Group_Name)}</h4>
                      </div>
                      <div className="detail">
                        <ul className="list ps-3">
                          <span>{item.Email_Address}</span>
                        </ul>
                        <svg
                          className={`${`package_${index}`}`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M4.44665 0.840041C5.2567 0.506673 6.12402 0.334568 6.99998 0.333374C7.87332 0.333374 8.73998 0.506707 9.55332 0.840041C10.36 1.17337 11.0933 1.66671 11.7133 2.28671C12.3333 2.90671 12.8266 3.64004 13.16 4.44671C13.4933 5.26004 13.6666 6.12671 13.6666 7.00004C13.6666 8.76671 12.9666 10.4667 11.7133 11.7134C11.095 12.3332 10.3603 12.8249 9.55145 13.1601C8.74261 13.4953 7.87553 13.6675 6.99998 13.6667C6.12402 13.6655 5.2567 13.4934 4.44665 13.16C3.63848 12.8246 2.90446 12.333 2.28665 11.7134C1.66678 11.095 1.17513 10.3604 0.839927 9.55151C0.504721 8.74267 0.332557 7.87559 0.333315 7.00004C0.333315 5.23337 1.03332 3.53337 2.28665 2.28671C2.90665 1.66671 3.63998 1.17337 4.44665 0.840041ZM6.99998 10.3334L8.03998 8.05337L10.3333 7.00004L8.03998 5.96004L6.99998 3.66671L5.95332 5.96004L3.66665 7.00004L5.95332 8.05337L6.99998 10.3334Z"
                            fill="#ff1500"
                          />
                        </svg>
                      </div>
                      <ul className="list">
                        <li><label htmlFor="">Balance:</label> <span>{item.Investment_Balance}</span></li>
                        <li><label htmlFor="">Cash:</label> <span>{item.Distribution_Balance}</span></li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          )
        })
      )
    }
    else {
      return (
        <tr>
          <td colSpan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
        </tr>
      )
    }

  }

  return (
    <>
      <AdminRightsCheck />
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <ToastContainer />
          <PageTitle title="Our Franchisees" />
          <div className="admin-content">
            <div className="row gy-4">
              {renderLedger()}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FranchiseesDashboard