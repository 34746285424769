import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import Form from 'react-bootstrap/Form';
import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";
import AllFranchiseesDropDown from "../../Common/Dropdowns/AllFranchiseesDropDown";
import { Modal } from "react-bootstrap";

function FranchiseDetail(props) {
    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );

    const [showFilters, setShowFilters] = useState(true);

    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfRecord, setListOfRecord] = useState([]);
    const [Category, setCategory] = useState("");
    const [Channel, setChannel] = useState("");

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();

    const [listOfLedger, setLedger] = useState([]);
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Full_Name, setFull_Name] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');
    const [show, setShow] = useState(false);
    const [AdminRemarks, setAdminRemarks] = useState('');
    const [DepositTID, setDepositTID] = useState('');
    const [DepositID, setDepositID] = useState('');
    const [SearchReference, setSearchReference] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = (ID, AdminRemarks, DepositTID) => {
        setShow(true);
        setDepositID(ID);
        setDepositTID(DepositTID)
        setAdminRemarks(AdminRemarks);
    };

    useEffect(() => {
        document.title = "Mag Holdings Admin";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            Category: Category,
            startDate: StartDate,
            endDate: EndDate,
            UserName: Full_Name,
        };

        // console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetAdminFeeDetails",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code === 1) {
                    // console.log(response.data);
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    setGrandTotal(response.data);
                } else if (
                    (response.data.status_code === 2 &&
                        response.data.status_message === "Invalid Request Parameters") ||
                    response.data.status_code === 0
                ) {
                    navigate("/admin/login");
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [pageNo, pageSize, isUpdated, Category, StartDate, EndDate]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{moment(item.Created_Date).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{moment(item.Updated_Date).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{item.User_Name}</td>
                        <td>PKR {NumberFormat(item.Amount)}</td>
                        <td>{item.FeePercentage} %</td>
                        <td>PKR {NumberFormat(item.FeeAmount)}</td>
                        <td>{item.Status}</td>
                        <td>
                            <ul
                                className="inline-action"
                            >
                                <li>
                                    <button
                                        onClick={(ID, AdminRemarks) => { handleShow(item.ID, item.AdminRemarks, item.PaymentID) }}
                                        className="primary "
                                    >
                                        <span className="material-symbols-outlined">
                                            view_in_ar
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="8">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };
    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsUpdated(true);
    };

    return (
        <>
            <AdminRightsCheck />
            <Modal
                className="modal-theme size-md p-0"
                show={show} onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cash Slip</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-start">
                    <img
                        src={config.root + `Personal/MemberImages/Deposits/` + DepositTID + "DepositSlip" + ".jpg"}
                        className="img-fluid"
                        alt="Attachment"
                    />
                    <div className="form_default my-3 position-relative">
                        <div className="form-group">
                            <label htmlFor="">Admin Remarks</label>
                            <div className="inputwrap">
                                <textarea type="text"
                                    className="form-control"
                                    readOnly
                                    disabled
                                    defaultValue={AdminRemarks}
                                />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
            <div className="admin-content">

                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Franchise Ledger
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/franchisees-dashboard">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row gy-3 align-items-end p-3">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">SELECT CHANNEL</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={Channel}
                                                    onChange={(e) => setChannel(e.target.value)}
                                                >
                                                    <option value="All"> All </option>
                                                    <AllFranchiseesDropDown />
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Dated</th>
                                    <th>Sender</th>
                                    <th>Beneficiary</th>
                                    <th>Receivable Amount</th>
                                    <th>Transfer Type</th>
                                    <th>IP Address</th>
                                    <th>Comments</th>
                                    <th>Slip</th>

                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="8">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {renderLedger()}
                                        {
                                            listOfLedger.length > 0 &&
                                            <>
                                                <tr style={{ borderButtom: "none !important" }}>
                                                    <th className="text-end border-bottom-0 pt-3" colSpan={4}>Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Amount'))}</th>
                                                    <th className="border-bottom-0 pt-3"></th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'FeeAmount'))}</th>
                                                </tr>
                                                <tr className="">
                                                    <th className="text-end border-bottom-0" colSpan={4}>Grand Total: </th>
                                                    <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.totalReceived)}</th>
                                                    <th className="border-bottom-0 pt-3"></th>
                                                    <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.totalFee)}</th>
                                                </tr>
                                            </>
                                        }
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>

        </>
    );
}

export default FranchiseDetail;
