import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import { Badge } from "react-bootstrap";
import Update_Profile from "./Update_Profile";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";
import Funds_Transfer from "./Funds_Transfer";
import NavDropdown from 'react-bootstrap/NavDropdown';
import ViewPassword from "./ViewPassword";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Overview(props) {
  const userstatus = useParams();
  const userstatusVal = userstatus.userstatus;


  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  //   console.log(status);

  const urlParams = new URLSearchParams(window.location.search);
  const Params_ID = urlParams.get('ID');

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  let navigate = useNavigate();


  const [Designation, setDesignation] = useState('');

  const [ledgerOld, setLedgerOld] = useState([]);
  const [Records, setRecords] = useState([])

  const [showFilters, setShowFilters] = useState(true);
  const [SearchdMembers, setSearchedMembers] = useState([]);

  const [RecordType, setRecordType] = useState("All");
  const [status, setStatus] = useState("");
  const [RankName, setRankName] = useState('');
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [Full_Name, setFull_Name] = useState('');
  const [RestrictStatus, setRestrictStatus] = useState('');
  const [EmailStatus, setEmailStatus] = useState('');
  const [GrandTotal, setGrandTotal] = useState('');

  useEffect(() => {
    document.title = "Mag Holdings Admin";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      Status: status,
      RankName: RankName,
      startDate: StartDate,
      endDate: EndDate,
      Full_Name: Full_Name,
      restrictStatus: RestrictStatus,
      Email_Address_Verified: EmailStatus,
      RecordType: RecordType,
      Postal_Address: Designation,
    };

    // console.log("data", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetMembersOverView",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setGrandTotal(response.data);
          setTotalRecords(response.data.totalRecords);
          //console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, status, RankName, StartDate, EndDate, RestrictStatus, EmailStatus, RecordType,Designation]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };



  const [showModal, setShowModal] = useState(false);
  const [User_ID, setUser_ID] = useState(null);
  const handleShowProfileModal = (item) => {
    // console.log(item);
    setUser_ID(item);
    setShowModal(true);
  }

  const handleCloseModal = (closeModal) => {
    setShowModal(closeModal);
    setUser_ID('');
  }
  const [ShowFundTransferModal, setShowFundTransferModal] = useState(false);
  const handleShowFundTransferModal = (item) => {
    setUser_ID(item);
    setShowFundTransferModal(true);
  }

  const handleCloseFundTransferModal = () => {
    setShowFundTransferModal(false);
    setUser_ID('');
  }



  // filter by inputs ...................
  const filterAlls = (e) => {
    let term = e.target.value;
    if (term !== "") {
      let filteredArray = listOfLedger.filter((object) =>
        Object.values(object).some((value) =>
          value.toString().toLowerCase().includes(term.toLowerCase())
        )
      );
      // console.log("filtered ", filteredArray);

      setLedger(filteredArray);
    } else {
      setLedger(ledgerOld);
    }
  };
  const FilteredData = (e) => {
    //  console.log(listOfLedger);
    let value = e.target.value;

    if (value == "All") {
      setRecords(ledgerOld);
    }
    else {
      const filterRecord = setRecords(listOfLedger.filter(item => item.PaymentMode === value));
      // console.log(filterRecord.length);

    }


  }


  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
            <td>{moment(item.Created_Date).format("DD/MM/YYYY HH:mm")}</td>
            <td>{item.Full_Name}</td>
            <td>{item.User_Name}</td>
            <td>{item.Email_Address}</td>
            <td className="text-center">
              {item.Email_Address_Verified ?
                <Badge bg="success">Yes</Badge>
                :
                <Badge bg="danger">No</Badge>
              }
            </td>
            <td className="text-center">
              {/* {item.MemberStatus === "Active" ? (
                <span className="badge" style={{ backgroundColor: "#B69541" }}>Active</span>
              ) : item.MemberStatus === "Inactive" ? (
                <Badge bg="secondary">Inactive</Badge>
              ) : item.MemberStatus === "Paid" ? (
                <Badge bg="dark">Paid</Badge>
              ) : item.MemberStatus === "Blocked" ? (
                <Badge bg="danger">Blocked</Badge>
              ) : item.MemberStatus === "Free" ? (
                <Badge bg="success">Free</Badge>
              ) : item.MemberStatus === "Ambassador" ? (
                <Badge bg="success">Ambassador</Badge>
              ) : null}
              {item.FreeMember === "Yes" ? <Badge bg="success" className="ms-1">Free</Badge> : ""} */}

              {item.Member_Type == 'Standard' ? <Badge bg="dark" className="ms-1">Free</Badge> : <Badge bg="success" style={{ backgroundColor: "#B69541" }} className="ms-1">Paid</Badge>}
              {item.FreeMember == 'Yes' ? <Badge bg="dark" className="ms-1">Free</Badge> : ''}

            </td>
            <td>
              {item.KYCStatus}
            </td>
            <td className="text-center">
              {item.RestrictWithdrawl ?
                <Badge bg="danger" className="mx-2">Withdraw</Badge>
                :
                <>
                </>
              }
              {item.RestrictROItransfer ?
                <Badge bg="danger">ROI</Badge>
                :
                <>
                </>
              }
            </td>
            <td>{item.Postal_Address}</td>
            <td>
              <div className="d-flex">
                {item.IsLeader ?
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1210_6)">
                      <path d="M5.99707 13.158C5.99707 13.9166 6.3281 14.6269 6.90051 15.1235L6.87293 15.1855H6.97637C6.98327 15.1855 6.98327 15.1924 6.99017 15.1924L9.96948 17.5442L10.0178 17.5855L13.0453 15.1993C13.0522 15.1993 13.0522 15.1924 13.0591 15.1855H13.1074V15.1442C13.6936 14.6476 14.0315 13.9304 14.0315 13.1648V8.02002H5.99707V13.158ZM8.7212 12.8545L10.0178 9.50968L11.3143 12.8545H8.7212ZM8.44534 13.5786H11.5902L12.135 14.9717L10.0178 16.6407L7.90051 14.9717L8.44534 13.5786ZM13.3005 8.75795V13.1648C13.3005 13.6062 13.135 14.0338 12.8315 14.3786L10.6453 8.76485H13.3005V8.75795ZM9.40396 8.75795L7.21086 14.3717C6.90741 14.0338 6.7419 13.6062 6.7419 13.158V8.75795H9.40396Z" fill="#0B1911" />
                      <path d="M13.6678 6.59229H6.35742V7.60609H13.6678V6.59229Z" fill="#0B1911" />
                      <path d="M10.0165 4.33548C10.4393 4.33548 10.782 3.99274 10.782 3.56996C10.782 3.14717 10.4393 2.80444 10.0165 2.80444C9.59367 2.80444 9.25098 3.14717 9.25098 3.56996C9.25098 3.99274 9.59367 4.33548 10.0165 4.33548Z" fill="#0B1911" />
                      <path d="M6.3202 6.23196C6.74298 6.23196 7.08573 5.88922 7.08573 5.46645C7.08573 5.04366 6.74298 4.70093 6.3202 4.70093C5.89742 4.70093 5.55469 5.04366 5.55469 5.46645C5.55469 5.88922 5.89742 6.23196 6.3202 6.23196Z" fill="#0B1911" />
                      <path d="M8.7831 6.23196C9.20585 6.23196 9.54861 5.88922 9.54861 5.46645C9.54861 5.04366 9.20585 4.70093 8.7831 4.70093C8.36034 4.70093 8.01758 5.04366 8.01758 5.46645C8.01758 5.88922 8.36034 6.23196 8.7831 6.23196Z" fill="#0B1911" />
                      <path d="M10.4849 5.46655C10.4849 5.88724 10.8297 6.23206 11.2504 6.23206C11.6711 6.23206 12.0159 5.88724 12.0159 5.46655C12.0159 5.04586 11.6711 4.70103 11.2504 4.70103C10.8228 4.69413 10.4849 5.03896 10.4849 5.46655Z" fill="#0B1911" />
                      <path d="M13.7128 6.23196C14.1356 6.23196 14.4783 5.88922 14.4783 5.46645C14.4783 5.04366 14.1356 4.70093 13.7128 4.70093C13.29 4.70093 12.9473 5.04366 12.9473 5.46645C12.9473 5.88922 13.29 6.23196 13.7128 6.23196Z" fill="#0B1911" />
                      <path d="M10.0152 0.509277C4.67729 0.509277 0.339355 4.84721 0.339355 10.1852C0.339355 15.5231 4.67729 19.861 10.0152 19.861C15.3532 19.861 19.6911 15.5231 19.6911 10.1852C19.6911 4.84721 15.3532 0.509277 10.0152 0.509277ZM10.0152 18.8334C5.24281 18.8334 1.36694 14.9507 1.36694 10.1852C1.36694 5.41962 5.2497 1.53686 10.0152 1.53686C14.7807 1.53686 18.6635 5.41962 18.6635 10.1852C18.6635 14.9507 14.7876 18.8334 10.0152 18.8334Z" fill="#0B1911" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1210_6">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  :
                  null
                }
                <span className="ms-2">
                  {item.RankName}
                </span>
              </div>
            </td>
            <td>{item.Current_Balance}</td>
            <td>{item.MagWallet}</td>
            <td>{item.Investment_Balance}</td>
            <td>{item.totalBasic}</td>
            <td>{item.totalStarter}</td>
            <td>{item.totalStandard}</td>
            <td>{item.totalSuperior}</td>
            <td>{item.totalFreedom}</td>
            <td>{item.totalLeader}</td>
            <td>{item.BinaryMembership}</td>
            <td>{item.RealestoMembership}</td>
            <td>{item.CommodityMembership}</td>
            <td>{item.totalCompounding}</td>
            <td>
              <NavDropdown title={`Action`} className="button small-button" id={`dropdown-basic-${index}`}>
                <Link className="dropdown-item" onClick={(e) => handleShowFundTransferModal(item.Nodes_IN_PK_Code)} >Funds Transfer</Link>
                {/* <Link className="dropdown-item" to="/admin/userstatistics-dashboard/Stateements">Statement</Link> */}
                <Link className="dropdown-item" onClick={(e) => handleShowProfileModal(item.Nodes_IN_PK_Code)} >Update Profile</Link>
              </NavDropdown>
            </td>
          </tr >
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="22">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsUpdated(true);
  };


  return (
    <>
      <AdminRightsCheck />
      <Modal
        className="admin-support-modal modal-theme  p-0"
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-start">
          <Update_Profile
            handleCloseModal={handleCloseModal}
            ID={User_ID} />
        </Modal.Body>
      </Modal>
      {/* {console.log(handleCloseModal)} */}
      <Modal
        className="admin-support-modal modal-theme  p-0"
        show={ShowFundTransferModal}
        onHide={handleCloseFundTransferModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Funds Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-start">
          <Funds_Transfer ID={User_ID} />
        </Modal.Body>
      </Modal>
      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Members Overview
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to="/admin/dashboard">
                <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row align-items-end p-3 gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Search by category</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="" selected>
                            All
                          </option>
                          <option value="Paid"> Paid</option>
                          <option value="Free"> Free</option>
                          <option value="Blocked"> Blocked</option>
                          <option value="Verified"> Verified</option>
                          <option value="Not Verified"> Not Verified</option>
                          <option value="Under Review"> Under Review</option>
                          <option value="FreePaid">Free / Paid</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Search Email Status</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={EmailStatus}
                          onChange={(e) => {
                            setEmailStatus(e.target.value);
                            filterAlls(e);
                          }}
                        >
                          <option value="" selected>All</option>
                          <option value="true">Email Verified</option>
                          <option value="false">Email Not Verified</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Search by Rank</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={RankName}
                          onChange={(e) => setRankName(e.target.value)}
                        >
                          <option value="" selected>
                            All
                          </option>
                          <option value="Pearl">Pearl</option>
                          <option value="Sapphire">Sapphire</option>
                          <option value="Emerald">Emerald</option>
                          <option value="Gold">Gold</option>
                          <option value="Black Gold">Black Gold</option>
                          <option value="Gold King">Gold King</option>
                          <option value="Diamond">Diamond</option>
                          <option value="Diamond Lion">Diamond Lion</option>
                          <option value="Diamond King">Diamond King</option>
                          <option value="Black Diamond King">Black Diamond King</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Designation</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={Designation}
                          onChange={(e) =>
                            setDesignation(e.target.value)
                          }
                        >
                          <option value="" disabled>All</option>
                          <option value="Regional Sales Manager">Regional Sales Manager</option>
                          <option value="Sales Director">Sales Director</option>
                          <option value="Managing Director">Managing Director</option>
                          <option value="Country Head">Country Head</option>
                          <option value="District Sales Manager">District Sales Manager</option>
                          <option value="Sales Manager">Sales Manager</option>
                        </select>
                      </div>

                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Restricted Members</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={RestrictStatus}
                          onChange={(e) => setRestrictStatus(e.target.value)}
                        >

                          <option value="All" selected>
                            All
                          </option>
                          <option value="RestrictWithdraw">Restrict Withdraw</option>
                          <option value="RestrictROI">Restrict ROI</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Search By Balance</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={RecordType}
                          onChange={(e) => setRecordType(e.target.value)}
                        >

                          <option value="All" selected>
                            All
                          </option>
                          <option value="Cash Balance">Cash Balance</option>
                          <option value="Holding Balance">Holding Balance</option>
                        </select>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">From</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={StartDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">To</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={EndDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form_default position-relative">
                      <Form onSubmit={handleSearch}>
                        <div className="row align-items-end gy-3 gx-1">
                          <div className="col-md-7">
                            <div className="form-group search_box">
                              <label htmlFor="">Search Member</label>
                              <div className="inputwrap">
                                <input type="text" className="form-control"
                                  placeholder="Find by Username/Member ID/Email"
                                  value={Full_Name}
                                  onChange={(e) => setFull_Name(e.target.value)}
                                />
                                <span className="material-symbols-outlined">search</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <button type="submit" className="button w-100 button-primary input-height input-radius">
                              search
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <ul className="downloads">
                      <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                        <Link target="_blank" to={`/admin/Members-overview/pdf/?StartDate=${StartDate}&EndDate=${EndDate}&Full_Name=${Full_Name}&status=${status}&RankName=${RankName}&RestrictStatus=${RestrictStatus}&EmailStatus=${EmailStatus}&RecordType=${RecordType}&Designation=${Designation}`}>PDF</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Created Date</th>
                  <th>Member Name</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Email Verified</th>
                  <th>Member Status</th>
                  <th>KYC Status</th>
                  <th>Restrict Status</th>
                  <th>Designation</th>
                  <th>Member Rank</th>
                  <th>Cash Balance</th>
                  <th>Holding Balance</th>
                  <th>Total Packages</th>
                  <th>Basic</th>
                  <th>Starter</th>
                  <th>Standard</th>
                  <th>Superior</th>
                  <th>Freedom</th>
                  <th>Leader</th>
                  <th>Gold</th>
                  <th>Diamond</th>
                  <th>Platinum</th>
                  <th>Titanium</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="22">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}
                    {
                      listOfLedger.length > 0 &&
                      <>
                        <tr style={{ borderButtom: "none !important" }}>
                          <th colSpan={10} className="border-bottom-0 pt-3"></th>
                          <th className="text-center border-bottom-0 pt-3">Subtotal:</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Current_Balance'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'MagWallet'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Investment_Balance'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'totalBasic'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'totalStarter'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'totalStandard'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'totalSuperior'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'totalFreedom'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'totalLeader'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'BinaryMembership'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'RealestoMembership'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'CommodityMembership'))}</th>
                          <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'totalCompounding'))}</th>
                        </tr>
                        <tr className="">
                          <th className="pt-3" colSpan={10}></th>
                          <th className="text-center">Grand Total:</th>
                          <th>PKR {NumberFormat(GrandTotal.totalBalance)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalMagWallet)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalMembership)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalBasic)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalStarter)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalStandard)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalSuperior)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalFreedom)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalLeader)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalBinary)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalRealesto)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalCommodity)}</th>
                          <th>PKR {NumberFormat(GrandTotal.totalTitanium)}</th>
                        </tr>
                      </>
                    }
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div >
    </>
  );
}

export default Overview;
