import React, { useState, useEffect } from "react";

import { Form } from "react-bootstrap";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/images/logo/mag.svg";
import background from "../../assets/images/franchise/franchise-bg.png";
import { ArrowLeftEndOnRectangleIcon, EyeIcon, EyeSlashIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import ReCAPTCHA from "react-google-recaptcha";
function FranchiseLogin(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');

  let navigate = useNavigate();

  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  }



  useEffect(() => {
    // console.log(window.location.host);
    document.title = "Login";
    if (AccessKey != null) {
      const dashboardParms = {
        AccessKey: AccessKey,
        UserID: UserID,
      };
      var api_config = {
        method: "post",
        url: config.base_url + "Members/GetDashboardData",
        headers: {
          "Content-Type": "application/json",
        },
        data: dashboardParms,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_code == 1) {
            navigate("/franchise/dashboard");
          }
          else {
            navigate("/franchise/login")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      //navigate("/login");
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (captchaValue) {
        setLoading(true);
        const signInData = {
          Email_Address: Email,
          Password: Password,
        };

        var api_config = {
          method: "post",
          url: config.base_url + "Users/SignIn",
          headers: {
            "Content-Type": "application/json",
          },
          data: signInData,
        };
        axios(api_config)
          .then(function (response) {
            // console.log(response.data);
            if (response.data.status_code == 1) {
              localStorage.setItem("AdminAccessKey", response.data.AccessKey);
              localStorage.setItem("AdminID", response.data.ID);
              localStorage.setItem("Email_Address", response.data.Email_Address);
              localStorage.setItem("AdminFull_Name", response.data.Full_Name);
              localStorage.setItem("Group_Name", response.data.Group_Name);
              localStorage.setItem("Description", response.data.Description);
              localStorage.setItem("MemberType", "Admin");
              toast.success("You are Logged in..Wait..", {
                position: toast.POSITION.TOP_RIGHT,
              });

              navigate("/franchise/dashboard");
              window.scrollTo(0, 0);

            } else {
              toast.error(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            toast.error("Network Error..", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        toast.error("Please complete the CAPTCHA", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    setValidated(true);
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ToastContainer />
      <section className='business-signin-form login-page'>
        <img src={background} />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className='container'>
            <div className="login_box">
              <div className="login_box_head">
                <img src={Logo} />
                <h4>Sign in to continue to Mag Holdings.</h4>
              </div>
              <div className="login_box_body">
                <div className="icon-input">
                  <input
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    value={Email}
                    placeholder='Email'
                    className=""
                    required
                    autoComplete="off"
                  />
                  <div className="icon">
                    <UserCircleIcon />
                  </div>
                </div>
                <div className="icon-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={Password}
                    placeholder='Login Password'
                    autoComplete="off"
                    className=""
                    required
                  />
                  <div className="icon" onClick={togglePasswordVisibility} >
                    {
                      showPassword ?
                        <EyeSlashIcon />
                        :
                        <EyeIcon />
                    }
                  </div>
                </div>
                <div className="mb-3">

                  <ReCAPTCHA
                    theme="light"
                    sitekey="6LfnFz0qAAAAAAblPmVjM6m4G_oG_4DxLAAtY6UE"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <button style={{position: "relative", zIndex: 2}} type="submit" className="button button-bs w-100"
                  disabled={loading || Email.length == 0 || Password.length == 0}>
                  LOGIN {loading ? '...' : ''}<span><ArrowLeftEndOnRectangleIcon /></span>
                </button>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </>
  );
}

export default FranchiseLogin;
