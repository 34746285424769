import React, { useState } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import config from '../../Config';
import axios from 'axios';
import logo from '../../assets/images/logo/mag.svg';
import background from '../../assets/images/partner/login-bg.png';
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ArrowLeftEndOnRectangleIcon, EyeIcon, EyeSlashIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import ReCAPTCHA from 'react-google-recaptcha';


const Login = () => {


  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  let navigate = useNavigate();


  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  }




  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (captchaValue) {
        setLoading(true);
        const signInData = {
          Email_Address: event.target.Email_Address.value,
          Password: event.target.Password.value,
        };

        var api_config = {
          method: "post",
          url: config.base_url + "Members/SignIn",
          headers: {
            "Content-Type": "application/json",
          },
          data: signInData,
        };
        axios(api_config)
          .then(function (response) {
            // console.log(response.data);
            if (response.data.status_code == 1) {
              localStorage.setItem("AccessKey", response.data.AccessKey);
              localStorage.setItem("ID", response.data.ID);
              localStorage.setItem("Full_Name", response.data.Full_Name);
              localStorage.setItem("Email_Address", response.data.Email_Address);
              localStorage.setItem("Img_File", response.data.Img_File);
              localStorage.setItem("User_Name", response.data.User_Name);
              localStorage.setItem("theme", "light");
              toast.success("You are Logged in..Wait..", {
                position: toast.POSITION.TOP_RIGHT,
              });

              navigate("/partner/dashboard");
              window.scrollTo(0, 0);

            } else {
              toast.error(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            toast.error("Network Error..", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        toast.error("Please complete the CAPTCHA", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    setValidated(true);
  };

  return (
    <>
      <ToastContainer />
      <section className='business-signin-form login-page'>
        <img src={background} />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className='container'>
            <div className="login_box">
              <div className="login_box_head">
                <img src={logo} />
                <h4>Login to app, enter your Login ID and Password</h4>
              </div>
              <div className="login_box_body">
                <div className="icon-input">
                  <input
                    type="text"
                    name="Email_Address"
                    placeholder='Login ID'
                    className=""
                    required
                  />
                  <div className="icon">
                    <UserCircleIcon />
                  </div>
                </div>
                <div className="icon-input m-0">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="Password"
                    placeholder='Login Password'
                    className=""
                    required
                  />
                  <div className="icon" onClick={togglePasswordVisibility} >
                    {
                      showPassword ?
                        <EyeSlashIcon />
                        :
                        <EyeIcon />
                    }
                  </div>
                </div>
                <Link className='link' to={`/forgot-password`}>Forgot Password ?</Link>
                <div className="mb-3">
 
                  <ReCAPTCHA
                    theme="light"
                    sitekey="6LfnFz0qAAAAAAblPmVjM6m4G_oG_4DxLAAtY6UE"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <button style={{position: "relative", zIndex: 2}} type="submit" className="button button-bs w-100"
                  disabled={loading}>
                  LOGIN {loading ? '...' : ''}<span><ArrowLeftEndOnRectangleIcon /></span>
                </button>
                <NavLink to={'/signup'} className='mt-3 d-flex m-auto justify-content-center'>SIGN UP</NavLink>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </>
  );
};

export default Login;
