import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";
import Form from 'react-bootstrap/Form';
import ReactTooltip from "react-tooltip";
import { JsonToExcel } from "react-json-to-excel";
import AllFranchiseesDropDown from "../../Common/Dropdowns/AllFranchiseesDropDown";

function Initiated(props) {
    const userstatus = useParams();
    const userstatusVal = userstatus.userstatus;

    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [ledgerOld, setLedgerOld] = useState([]);

    const [loadingBtn, setLoadingBtn] = useState(false);
    const [Channel, setChannel] = useState('');
    const [ExcelPageSize, setExcelPageSize] = useState(20000);
    const [ExcelArray, setExcelArray] = useState([]);

    const [status, setStatus] = useState("Initiated");
    const [allStatus, setallStatus] = useState("");

    const [statusChangedSuccess, setStatusChangedSuccess] = useState(false);

    const [showFilters, setShowFilters] = useState(true);
    const [paymentMode, setpaymentMode] = useState("All");




    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isFilteredByDate, setisFilteredByDate] = useState(false);

    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Full_Name, setFull_Name] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Mag Holdings Admin";
        setStatusChangedSuccess(false);
        
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            Status: userstatusVal ? userstatusVal : status,
            startDate: StartDate,
            endDate: EndDate,
            UserName: Full_Name,
            PaymentMode: paymentMode,
            Channel: Channel,
        };


        var api_config = {
            method: "post",
            url: config.base_url + "/users/GetWithdrawalByStatus",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code === 1) {
                    //to remove all null values
                    const filtered = response.data.ListofRecords.map((obj) => {
                        const convertobj = { ...obj };
                        for (let key in convertobj) {
                            if (convertobj[key] === null) {
                                convertobj[key] = "";
                            }
                        }
                        return convertobj;
                    });
                    setLedger(filtered);
                    setLedgerOld(filtered);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    setGrandTotal(response.data);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    navigate("/admin/login");
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
            });
    }, [
        pageNo,
        pageSize,
        isUpdated,
        userstatusVal,
        status,
        statusChangedSuccess,
        EndDate,
        StartDate,
        paymentMode,
        Channel
    ]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const [checkedIds, setCheckedIds] = useState({});

    const handleCheckboxChange = (e) => {
        const checkedId = e.target.value;
        const isChecked = e.target.checked;

        setCheckedIds((prevCheckedIds) => ({
            ...prevCheckedIds,
            [checkedId]: isChecked,
        }));
    };

    const handleCheckAll = (e) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            const allIds = listOfLedger.map((item) => item.ID);
            const newCheckedIds = allIds.reduce((acc, id) => {
                acc[id] = true;
                return acc;
            }, {});
            setCheckedIds(newCheckedIds);
        } else {
            setCheckedIds({});
        }
    };



    // update individual row status ...................
    const UpdateStatus = (e, ID) => {


        const val = e.target.value;
        const confirm = window.confirm(`Are you sure you want to proceed with ${val} status?`);
        if (confirm) {
            const updated = listOfLedger.map((row) => {
                if (row.ID === ID) {
                    return { ...row, Status: val };
                } else return row;
            });
            setLedger(updated);

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: ID,
            };

            // REJECTED................
            if (val === "Rejected") {

                var api_config = {
                    method: "post",
                    url: config.base_url + "/Users/RejectWithdrawlRequest",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                axios(api_config)
                    .then(function (response) {
                        if (response.data.status_code === 1) {
                            // setStatusById("Rejected")
                            setStatusChangedSuccess(true);
                            toast.error("Cancled Successfully !", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        } else if (
                            response.data.status_code === 0 &&
                            response.data.status_message === "Failed to cancel the request!"
                        ) {
                            toast.error("Failed  To Reject !", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        } else if (
                            (response.data.status_code === 0 &&
                                response.data.status_message === "Invalid Access Key.") ||
                            (response.data.status_code === 2 &&
                                response.data.status_message === "Invalid Request Parameters")
                        ) {
                            navigate("/admin/login");
                        }
                    })
                    .catch(function (error) {
                    });
            }

            // APPROVED................

            if (val === "Approved") {

                var api_config = {
                    method: "post",
                    url: config.base_url + "/users/ApproveWithdrawlRequest",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                axios(api_config)
                    .then(function (response) {
                        if (response.data.status_code === 1) {
                            // setStatusById("Rejected")
                            setStatusChangedSuccess(true);
                            toast.success("Approved Successfully !", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        } else if (
                            response.data.status_code === 2 &&
                            response.data.status_message === "Invalid Request Parameters"
                        ) {
                            toast.error("Failed  To Approve !", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        } else if (
                            response.data.status_code === 0 &&
                            response.data.status_message === "Invalid Access Key."
                        ) {
                            navigate("/admin/login");
                        }
                    })
                    .catch(function (error) {
                    });
            }

            //TRANSFER..................
            // if (val === "Success") {

            //     var api_config = {
            //         method: "post",
            //         url: config.base_url + "/users/SuccessWithdrawlRequest",
            //         headers: {
            //             "Content-Type": "application/json",
            //         },
            //         data: data,
            //     };
            //     // setStatusChangedSuccess(false);
            //     axios(api_config)
            //         .then(function (response) {
            //             if (response.data.status_code === 1) {
            //                 // setStatusById("Rejected")
            //                 setStatusChangedSuccess(true);
            //                 toast.success("Success Successfully !", {
            //                     position: toast.POSITION.TOP_RIGHT,
            //                 });
            //             } else if (
            //                 (response.data.status_code === 0 &&
            //                     response.data.status_message ===
            //                     "Failed to transfer the request!") ||
            //                 (status === "Initiated" && val === "Success")
            //             ) {
            //                 toast.error("Success Failed ! First Approve it to Transfer.", {
            //                     position: toast.POSITION.TOP_RIGHT,
            //                 });
            //             } else if (
            //                 (response.data.status_code === 0 &&
            //                     response.data.status_message === "Invalid Access Key.") ||
            //                 (response.data.status_code === 0 &&
            //                     response.data.status_message === "Invalid Request Parameters")
            //             ) {
            //                 navigate("/admin/login");
            //             }
            //         })
            //         .catch(function (error) {
            //         });
            // }
        }
    };
    // update all rows status ...................

    const UpdateAllStatus = (e, ID) => {

        const val = e.target.value;
        const confirm = window.confirm(`Are you sure you want to proceed with ${val} status?`);

      

        if (confirm) {
            if (listOfLedger != null && listOfLedger.length > 0) {
                setIsUpdated(true);
                // Filter the items that are checked
                const selectedItems = listOfLedger.filter(item => checkedIds[item.ID]);

                selectedItems.forEach((item) => {
                    const data = {
                        AccessKey: AccessKey,
                        UserID: UserID,
                        ID: item.ID,
                    };
                  
                    // REJECTED................
                    if (val === "Rejected") {

                        var api_config = {
                            method: "post",
                            url: config.base_url + "/Users/RejectWithdrawlRequest",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            data: data,
                        };
                        axios(api_config)
                            .then(function (response) {
                                if (response.data.status_code === 1) {
                                    toast.success("Cancled Successfully !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    setIsUpdated(false);
                                    // setTimeout(() => {
                                    //     window.location.reload();
                                    // }, 3000);
                                }
                                else {
                                    setIsUpdated(false);
                                    return false;
                                }
                            })
                            .catch(function (error) {
                            });
                    }

                    // APPROVED................

                    if (val === "Approved") {
                        var api_config = {
                            method: "post",
                            url: config.base_url + "/users/ApproveWithdrawlRequest",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            data: data,
                        };
                        axios(api_config)
                            .then(function (response) {
                                if (response.data.status_code === 1) {
                                    toast.success("Approved Successfully !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    setIsUpdated(false);
                                    // setTimeout(() => {
                                    //     window.location.reload();
                                    // }, 3000);
                                }
                                else {
                                    return false;
                                }
                            })
                            .catch(function (error) {
                            });
                    }

                })
            }
        }
    };
    // filter by date ...................

    const filterByDate = (e) => {
        let date = moment(e.target.value).format("MM DD YYYY");

        if (date != "") {
            let member = ledgerOld.filter(
                (member) => moment(member.Created_Date).format("MM DD YYYY") == date
            );

            setLedger(member);
        } else {
            setLedger(ledgerOld);
        }

        setisFilteredByDate(true);
    };
    // filter by inputs ...................
    const filterAlls = (e) => {
        let term = e.target.value;
        if (term !== "") {
            let filteredArray = listOfLedger.filter((object) =>
                Object.values(object).some((value) =>
                    value.toString().toLowerCase().includes(term.toLowerCase())
                )
            );

            setLedger(filteredArray);
        } else {
            setLedger(ledgerOld);
        }
    };



    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>
                            <div style={{ textAlign: "center" }}>
                                <input
                                    type="checkbox"
                                    className="form-check-input userCheck"
                                    value={item.ID}
                                    checked={checkedIds[item.ID] || false}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                        </td>
                        <td>{moment(item.Created_Date).format("DD/MM/YYYY HH:mm")}</td>
                        <td>{item.User_Name}</td>
                        <td>{item.Channel}</td>
                        <td>{item.PaymentID}</td>

                        <td>PKR {item.Amount}</td>
                        <td>PKR {item.FeeAmount}</td>
                        <td>{item.PaymentMode}</td>
                        <td>{item.BankName}</td>
                        <td>{item.AccountTitle}</td>
                        <td className="">
                            <div className="d-inline-flex justify-content-between align-items-center w-100">
                                <p className="m-0">
                                    {item.Address}
                                </p>
                                <button
                                    className="button "
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(item.Address);
                                        toast.success("Address Copied !", {
                                            position: toast.POSITION.TOP_RIGHT,
                                        });
                                    }}
                                >
                                    <span>
                                        <i
                                            style={{ fontSize: "18px" }}
                                            className="material-symbols-outlined"
                                        >
                                            content_copy
                                        </i>
                                    </span>
                                </button>
                            </div>
                        </td>
                        <td>{item.SelectedCurrencyRate}</td>
                        <td>{item.NetReturnableInCurrency}</td>
                        <td>{item.Status}</td>

                        <td>
                            <div className="icon-input m-0">
                                <select
                                    className="form-select"
                                    value={item.Status}
                                    onChange={(e) => UpdateStatus(e, item.ID)}
                                >
                                    <option selected value="Initiated">Initiated</option>
                                    <option value="Approved"> Approved</option>
                                    <option value="Rejected"> Rejected</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="14">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    // update multiple rows after select multiple rows
    const handleUpdateUsers = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const inputsStudents = document.querySelectorAll("input.userCheck");
        const valuesSelected = [];
        inputsStudents.forEach((input) => {
            if (input.checked == true) {
                const value = parseInt(input.value);
                if (value > 0) {
                    valuesSelected.push(value);
                }
            }
        });
        const commaSeparatedValues = valuesSelected.join(", ");

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            selectedValues: commaSeparatedValues,
        };

        // REJECTED................
        if (allStatus === "Rejected") {
            setLoadingBtn(true);
            var api_config = {
                method: "post",
                url: config.base_url + "users/RejectAllWithdrawlRequest",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code === 1) {
                        // setStatusById("Rejected")
                        setStatusChangedSuccess(true);
                        setLoadingBtn(false);
                        toast.success("Cancled Successfully !", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else if (
                        response.data.status_code === 2 &&
                        response.data.status_message === "Sorry, something went wrong."
                    ) {
                        toast.error("Failed  To Reject !", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else if (
                        (response.data.status_code === 0 &&
                            response.data.status_message === "Invalid Access Key.") ||
                        (response.data.status_code === 2 &&
                            response.data.status_message === "Invalid Request Parameters")
                    ) {
                        navigate("/admin/login");
                    }
                })
                .catch(function (error) {
                });
        }

        // TRANSFERD................
        if (allStatus === "Success") {
            setLoadingBtn(true);
            var api_config = {
                method: "post",
                url: config.base_url + "users/TransferedAllWithdrawlRequest",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            // setStatusChangedSuccess(false);
            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code === 1) {
                        // setStatusById("Rejected")
                        setStatusChangedSuccess(true);
                        setLoadingBtn(false);

                        toast.success("Transfered Successfully !", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else if (
                        response.data.status_code === 2 &&
                        response.data.status_message === "Sorry, something went wrong."
                    ) {
                        toast.error("Failed  To Reject !", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else if (
                        (response.data.status_code === 0 &&
                            response.data.status_message === "Invalid Access Key.") ||
                        (response.data.status_code === 2 &&
                            response.data.status_message === "Invalid Request Parameters")
                    ) {
                        navigate("/admin/login");
                    }
                })
                .catch(function (error) {
                });
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsUpdated(true);
    };



    useEffect(() => {
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: ExcelPageSize,
            Status: status,
            startDate: StartDate,
            endDate: EndDate,
            UserName: Full_Name,
            PaymentMode: paymentMode,
            Channel: Channel,
        };
        var api_config = {
            method: "post",
            url: config.base_url + "/users/GetWithdrawalByStatus",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                let data = response.data.ListofRecords;
                let Records = response.data.totalRecords;
                let count = 1;
                const listOfLedgerNew = data.map(({ ...item }) => ({
                    Serial_No: Records - count++ + 1,
                    CreatedDate: moment(item.Created_Date).format("DD/MM/YYYY HH:mm"),
                    UpdatedDate: moment(item.Updated_Date).format("DD/MM/YYYY HH:mm"),
                    UserName: item.User_Name,
                    UserName: item.Channel,
                    Transaction_ID: item.PaymentID,
                    Payment_Mode: item.PaymentMode,
                    Crypto_Address: item.Address,
                    Withdrawal_Amount: `PKR ${(item.Amount)}`,
                    Admin_Fee: `PKR ${(item.FeeAmount)}`,
                    Exchange_Rate: item.SelectedCurrencyRate,
                    Receivable_Amount: item.NetReturnableInCurrency,
                }));
                setExcelArray(listOfLedgerNew)
            })
            .catch(function (error) {
            });
    }, [pageNo, ExcelPageSize, isUpdated, status, statusChangedSuccess, StartDate, EndDate, paymentMode, Channel]);



    return (
        <>
            <AdminRightsCheck />
            <ToastContainer />
            <ReactTooltip />

            <div className="admin-content">


                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Initiated Withdrawal List
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <button type="button" onClick={() => window.history.back()} className="button button-outline-bs">
                                Back
                            </button>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Filters Menu */}
                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row p-3 align-items-end gy-3">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Select Payment Mode</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={paymentMode}
                                                    onChange={(e) => setpaymentMode(e.target.value)}
                                                >
                                                    <option value="All"> All </option>
                                                    <option value="USDT">TRC20 - USDT</option>
                                                    <option value="TRX">TRON - TRX</option>
                                                    <option value="BTC">Bitcoin - BTC</option>
                                                    <option value="Bank Account">Bank Account</option>
                                                    <option value="Cash">Cash</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">SELECT CHANNEL</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={Channel}
                                                    onChange={(e) => setChannel(e.target.value)}
                                                >
                                                    <option value="All"> All </option>
                                                    <AllFranchiseesDropDown />
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <ul className="downloads">
                                            <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                                                <Link target="_blank" to={`/admin/withdrawal-dashboard/initiated-List-pdf/?StartDate=${StartDate}&EndDate=${EndDate}&paymentMode=${paymentMode}&Full_Name=${Full_Name}&status=${status}&Channel=${Channel}`}>PDF</Link>
                                            </li>
                                            <li className="round-button" data-tip={'Download as Excel'} data-place="bottom">
                                                <JsonToExcel
                                                    title="EXCEL"
                                                    data={ExcelArray}
                                                    fileName="MAG INITIATED WITHDRAWAL LIST"
                                                    btnClassName="excel-button"
                                                />
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form_default position-relative">
                                            <Form onSubmit={handleSearch}>
                                                <div className="row align-items-end gy-3 gx-1">
                                                    <div className="col-md-7">
                                                        <div className="form-group search_box">
                                                            <label htmlFor="">Search Member</label>
                                                            <div className="inputwrap">
                                                                <input type="text" className="form-control"
                                                                    placeholder="Find by Username/Member ID/Email"
                                                                    value={Full_Name}
                                                                    onChange={(e) => setFull_Name(e.target.value)}
                                                                />
                                                                <span className="material-symbols-outlined">search</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type="submit" className="button w-100 button-primary input-height input-radius">
                                                            search
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <form onSubmit={handleUpdateUsers}>
                    <div className="card card-table mb-5 mt-3 form_admin">
                        <div className="card-inner responsive-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>select All</th>
                                        <th>Date</th>
                                        <th>Username</th>
                                        <th>Channel</th>
                                        <th>Transaction ID</th>
                                        <th>Withdrawal amount</th>
                                        <th style={{ minWidth: "120px" }}>Admin Fee</th>
                                        <th style={{ minWidth: "140px" }}>Payment Mode</th>
                                        <th>Bank Name</th>
                                        <th>Account Title</th>
                                        <th>Crypto Address / IBAN</th>
                                        <th>Exchange Rate</th>
                                        <th>Receivable amount</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="form_admin">

                                    <tr className="filter-row">
                                        <td></td>
                                        <td>
                                            <div style={{ textAlign: "center" }}>
                                                <input
                                                    type="checkbox"
                                                    name="allSelect"
                                                    onChange={handleCheckAll}
                                                    className="check"
                                                />
                                            </div>
                                        </td>

                                        <td>
                                            <div className="d-flex">
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        onChange={filterByDate}
                                                        className="form-control"
                                                    />
                                                </div>
                                                {isFilteredByDate === true && (
                                                    <button
                                                        className="button p-0 bg-transparent"
                                                        onClick={() => {
                                                            setisFilteredByDate(false);
                                                            setLedger(ledgerOld);
                                                        }}
                                                    >
                                                        <span
                                                            style={{ lineHeight: "unset" }}
                                                            className="material-symbols-outlined"
                                                        >
                                                            close
                                                        </span>
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="Username"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    style={{ width: "120px" }}
                                                    placeholder="Channel"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="Transaction ID"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>


                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="withdrawal Amount"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="Admin Fee"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="Payment Mode"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="Bank Name"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="Account Title"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="Crypto Address / IBAN"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="Currency Rate"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="Receivable Amount"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inputwrap">
                                                <input
                                                    type="text"
                                                    placeholder="Status"
                                                    onChange={(e) => filterAlls(e)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="" style={{ width: "150px" }}>

                                                <div className="icon-input m-0">
                                                    <select
                                                        className="form-select"
                                                        value={allStatus}
                                                        onChange={(e) => UpdateAllStatus(e)}
                                                    >
                                                        <option selected value="Initiated">Initiated</option>
                                                        <option value="Approved"> Approved</option>
                                                        <option value="Rejected"> Rejected</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <div className=""
                                                style={{ width: "11rem" }}>
                                                <div className="icon-input"
                                                >
                                                    <select
                                                        className="form-select"
                                                        value={allStatus}
                                                        onChange={(e) => UpdateAllStatus(e)}
                                                    >
                                                        <option selected value="Initiated">Initiated</option>
                                                        <option value="Approved"> Approved</option>
                                                        <option value="Rejected"> Rejected</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                        </td>
                                    </tr>
                                    {loading ? (
                                        <tr>
                                            <td className="text-center" colSpan="14">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>{renderLedger()}

                                            {
                                                listOfLedger != null && listOfLedger.length > 0 &&
                                                <>
                                                    <tr style={{ borderButtom: "none !important" }}>
                                                        <th colSpan={5} className="border-bottom-0 pt-3"></th>
                                                        <th className="text-end border-bottom-0 pt-3">Subtotal:</th>
                                                        <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Amount'))}</th>
                                                        <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'FeeAmount'))}</th>
                                                        <th className="border-bottom-0 pt-3" colSpan={5}></th>
                                                        <th className="border-bottom-0 pt-3">

                                                            {paymentMode != "All" && (
                                                                <span>{NumberFormat(calculateColumnTotal(listOfLedger, 'NetReturnableInCurrency'))}</span>
                                                            )}

                                                        </th>

                                                    </tr>
                                                    <tr className="">
                                                        <th className="pt-3 border-bottom-0" colSpan={5}></th>
                                                        <th className="text-end border-bottom-0">Grand Total: </th>
                                                        <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.grandTotal)}</th>
                                                        <th className="border-bottom-0">PKR {NumberFormat(GrandTotal.adminFee)}</th>
                                                        <th className="border-bottom-0 pt-3" colSpan={5}></th>
                                                        <th className="border-bottom-0">
                                                            {paymentMode != "All" && (
                                                                <span>{NumberFormat(GrandTotal.receiveableTotal)}</span>
                                                            )}
                                                        </th>
                                                        <th className="border-bottom-0"></th>
                                                    </tr>
                                                </>
                                            }
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>

                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                                setExcelPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Initiated;
