import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../Config';
import { PDFViewer, Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import Logo from '../../../assets/images/partner/mag_logo.png';
import { NumberFormat } from '../../Dashboard/General/Functions';
import { Badge } from 'react-bootstrap';



function OverviewPdf(props) {

    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [loading, setLoading] = useState(false);
    const [listOfRecord, setListOfRecord] = useState([]);

    const [totalRecords, setTotalRecords] = useState(0);

    const urlParams = new URLSearchParams(window.location.search);
    const StartDate = urlParams.get('StartDate');
    const EndDate = urlParams.get('EndDate');
    const Full_Name = urlParams.get('Full_Name');
    const RestrictStatus = urlParams.get('RestrictStatus');
    const RecordType = urlParams.get('RecordType');
    const status = urlParams.get('status');
    const EmailStatus = urlParams.get('EmailStatus');
    const RankName = urlParams.get('RankName');
    const Designation = urlParams.get('Designation');

    const [GrandTotal, setGrandTotal] = useState('');
    useEffect(() => {
        document.title = "Mag Holdings Admin";

        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: 1,
            pageSize: 20,
            Status: status,
            RankName: RankName,
            startDate: StartDate,
            endDate: EndDate,
            Full_Name: Full_Name,
            restrictStatus: RestrictStatus,
            Email_Address_Verified: EmailStatus,
            RecordType: RecordType,
            Postal_Address: Designation,
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetMembersOverView",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        // console.log(data);

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code === 1) {
                    // console.log("data", response.data);
                    setListOfRecord(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    setGrandTotal(response.data);
                }
                else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." || response.data.status_code === 2) {



                }
                else {

                }

            })
            .catch(function (error) {
                // console.log(error);
            });
    }, []);


    // Create styles
    const styles = StyleSheet.create({
        page: {
            //flexDirection: 'row',
            backgroundColor: '#FFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexDirection: 'column',
            marginTop: 0,
            marginBottom: 20
            //flexGrow: 1
        },
        header: {
            margin: 5,
            marginBottom: 6.5,
            padding: 0,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10
        },
        dataview: {
            fontSize: "8pt"
        },
        table: {
            display: 'table',
            width: '100%',
            borderStyle: 'solid',
            borderWidth: .5,
            borderColor: '#f0f0f0',
            marginTop: 0,
            marginBottom: 10,
        },
        tableHead: {
            flexDirection: 'row',
            backgroundColor: '#f0f0f0'
        },
        tablefooter: {
            flexDirection: 'row',
            backgroundColor: '#FF1500'
        },
        tabletophead: {
            flexDirection: 'row',
            backgroundColor: '#FF1500',
            color: '#fff',
            borderStyle: 'dashed',
            borderWidth: 1,
            borderColor: '#f0f0f0',
            borderBottomColor: '#fff',
        },
        thValuecenter: {
            fontSize: "10pt",
            textAlign: "center",
            color: '#fff',
            fontWeight: 'bold',
        },
        th: {
            flex: 1,
            padding: 5,
            borderStyle: 'solid',
            borderWidth: .1,
            borderColor: '#f0f0f0',
            color: '#000'
        },
        footerth: {
            flex: 1,
            padding: 5,
            borderStyle: 'solid',
            borderWidth: .1,
            borderColor: '#f0f0f0',
            color: '#fff'
        },
        thValue: {
            fontSize: "8pt",
            fontWeight: "bold"
        },

        row: {
            flexDirection: 'row',
        },
        td: {
            flex: 1,
            padding: 5,
            borderBottom: 1,
            borderStyle: 'solid',
            borderBottomWidth: .5,
            borderColor: '#f0f0f0',
        },
        tdValue: {
            fontSize: "8pt",
        },




    });


    const generateData = () => {
        if (listOfRecord != null && listOfRecord.length > 0) {
            let count = 1;
            return (
                listOfRecord.map((item, index) => {
                    return (
                        <View key={index} style={styles.row} break={index > 1 && index % 15 === 0}>
                            <View style={[styles.td, { flexGrow: 0, paddingRight: 20 }]}><Text style={styles.tdValue}>{totalRecords - count++ + 1}</Text></View>
                            <View style={styles.td}>
                                <Text style={styles.tdValue}> {moment(item.Created_Date).format("DD/MM/YYYY HH:mm")}</Text>
                            </View>
                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{(item.User_Name)}</Text><Text style={styles.tdValue}>{(item.Full_Name)}</Text></View>
                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{item.Email_Address_Verified ? "Yes" : "No"}</Text><Text style={styles.tdValue}>{(item.Email_Address)}</Text></View>
                            <View style={[styles.td, { flexGrow: 2 }]}>
                                <Text style={styles.tdValue}>
                                    {item.Member_Type == 'Standard' ? "Free" : "Paid"}
                                    {item.FreeMember == 'Yes' ? ' / Free' : ''}
                                </Text>

                                <Text style={styles.tdValue}>
                                    {item.RestrictWithdrawl ?
                                        "Withdraw"
                                        :
                                        ''
                                    }
                                    {item.RestrictROItransfer ?
                                        "ROI"
                                        :
                                        ''
                                    }
                                </Text>
                            </View>
                            <View style={[styles.td, { flexGrow: 1 }]}>
                                <Text style={styles.tdValue}>
                                    {item.KYCStatus}
                                </Text>
                            </View>

                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{(item.Current_Balance)}</Text><Text style={styles.tdValue}></Text></View>
                            <View style={[styles.td, { flexGrow: 2 }]}>
                                <Text style={styles.tdValue}>{(item.Investment_Balance)}</Text>
                                <Text style={styles.tdValue}>
                                    {item.IsLeader ?
                                        'Leader ,'
                                        :
                                        ''
                                    }
                                    {item.RankName}
                                </Text>
                            </View>
                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{(item.totalStarter)}</Text><Text style={styles.tdValue}>{(item.Postal_Address)}</Text></View>
                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{(item.totalStandard)}</Text><Text style={styles.tdValue}>{(item.totalSuperior)}</Text></View>
                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{(item.BinaryMembership)}</Text><Text style={styles.tdValue}>{(item.RealestoMembership)}</Text></View>
                            <View style={[styles.td, { flexGrow: 2 }]}><Text style={styles.tdValue}>{(item.CommodityMembership)}</Text><Text style={styles.tdValue}>{item.totalCompounding}</Text></View>

                        </View>
                    )
                })
            )
        }

    };

    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
                <View style={styles.header} fixed>
                    <Image
                        src={Logo}
                        style={{ width: 48, height: 'auto' }}
                    />
                </View>
                <View style={styles.section}>
                    <View style={styles.table}>
                        <View style={styles.tabletophead} fixed>
                            <View style={styles.th}><Text style={styles.thValuecenter}>MAG {RankName.length > 0 ? RankName.toUpperCase() + " RANK MEMBERS" : (RecordType != 'All' ? RecordType.toUpperCase() + ' MEMBERS' : "TOTAL MEMBERS OVERVIEW")} REPORT FROM {StartDate.length > 0 ? StartDate : '01/01/2024'} T0 {EndDate.length > 0 ? EndDate : (moment().format('DD/MM/YYYY'))}</Text></View>
                        </View>
                        <View style={styles.tableHead} fixed>
                            <View style={[styles.th, { flexGrow: 0, paddingRight: 20 }]}><Text style={styles.thValue}>SR.</Text></View>
                            <View style={styles.th}><Text style={styles.thValue}>Created Date</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Username</Text><Text style={styles.thValue}>Member Name</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Email Verified</Text><Text style={styles.thValue}>Email Address</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Member Status</Text><Text style={styles.thValue}>Restrict Status</Text></View>

                            <View style={[styles.th, { flexGrow: 1 }]}><Text style={styles.thValue}>KYC Status</Text></View>

                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Cash Balance</Text><Text style={styles.thValue}>Holding Balance</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Total Packages</Text><Text style={styles.thValue}>Member Rank</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Starter</Text><Text style={styles.thValue}>Designation</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Standard</Text><Text style={styles.thValue}>Superior</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Gold</Text><Text style={styles.thValue}>Diamond</Text></View>
                            <View style={[styles.th, { flexGrow: 2 }]}><Text style={styles.thValue}>Platinum</Text><Text style={styles.thValue}>Titanium</Text></View>
                        </View>
                        {generateData()}
                        {
                            listOfRecord != null && listOfRecord.length > 0 &&
                            <>
                                <View style={styles.tablefooter}>
                                    <View style={[styles.footerth, { flexGrow: 0, paddingRight: 20 }]}><Text style={styles.thValue}></Text></View>
                                    <View style={styles.footerth}><Text style={styles.thValue}></Text></View>
                                    <View style={[styles.footerth, { flexGrow: 2 }]}><Text style={styles.thValue}></Text></View>
                                    <View style={[styles.footerth, { flexGrow: 2 }]}><Text style={styles.thValue}></Text></View>
                                    <View style={[styles.footerth, { flexGrow: 1 }]}><Text style={styles.thValue}></Text></View>
                                    <View style={[styles.footerth, { flexGrow: 2 }]}><Text style={styles.thValue}>Grand Total: </Text></View>
                                    <View style={[styles.footerth, { flexGrow: 2 }]}><Text style={styles.thValue}>PKR {NumberFormat(GrandTotal.totalBalance)}</Text><Text style={styles.thValue}>PKR {NumberFormat(GrandTotal.totalMagWallet)}</Text></View>
                                    <View style={[styles.footerth, { flexGrow: 2 }]}><Text style={styles.thValue}>PKR {NumberFormat(GrandTotal.totalMembership)}</Text></View>
                                    <View style={[styles.footerth, { flexGrow: 2 }]}><Text style={styles.thValue}>PKR {NumberFormat(GrandTotal.totalStarter)}</Text></View>
                                    <View style={[styles.footerth, { flexGrow: 2 }]}><Text style={styles.thValue}>PKR {NumberFormat(GrandTotal.totalStandard)}</Text><Text style={styles.thValue}>PKR {NumberFormat(GrandTotal.totalSuperior)}</Text></View>
                                    <View style={[styles.footerth, { flexGrow: 2 }]}><Text style={styles.thValue}>PKR {NumberFormat(GrandTotal.totalBinary)}</Text><Text style={styles.thValue}>PKR {NumberFormat(GrandTotal.totalRealesto)}</Text></View>
                                    <View style={[styles.footerth, { flexGrow: 2 }]}><Text style={styles.thValue}>PKR {NumberFormat(GrandTotal.totalCommodity)}</Text><Text style={styles.thValue}>PKR {NumberFormat(GrandTotal.totalTitanium)}</Text></View>
                                </View>
                            </>
                        }
                    </View>
                    <View style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }} fixed>
                        <Text style={{ fontSize: '8pt' }} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} />
                    </View>
                </View>
            </Page>
        </Document>
    );

    return (
        <>
            <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
                {
                    loading ?
                        "Loading..."
                        :
                        <PDFViewer width="100%" height="100%"
                            fileName={`MAG ${RankName.length > 0 ? RankName.toUpperCase() + " RANK MEMBERS" : (RecordType != 'All' ? RecordType.toUpperCase() + ' MEMBERS' : "TOTAL MEMBERS OVERVIEW")} REPORT FROM ${StartDate.length > 0 ? StartDate : '01/01/2024'} T0 ${EndDate.length > 0 ? EndDate : (moment().format('DD/MM/YYYY'))}.pdf`}
                        >
                            <MyDocument />
                        </PDFViewer>
                }

            </div>
        </>
    );
}

export default OverviewPdf